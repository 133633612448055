<template>
  <section
    id="slide-first"
    class="primary py-10"
  >
  <br>
    <base-subheading
      align="center"
      title="Training Facility & Demonstration Centre"
      class="text-uppercase"
      space="2"
      style="color:white;"
    >
    </base-subheading>
    <br>
    <v-sheet
      class="mx-auto primary"
      dark
    >
      <v-slide-group
        class="pa-4"
      >
        <v-slide-item
          v-for="(model, n) in models"
          :key="n"
          class="align-self-center"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'accent' : 'white lighten-1'"
            class="ma-4 py-auto my-auto"
            height="450"
            width="300"
            @click="toggle"
          >
            <base-img
              :src="model.src"
              height="300"
              width="300"
            />
            <v-card-text
              class="text-center py-auto my-auto"
              :style="active ? 'color:#e8f1f2;font-weight: bold;font-family: Work Sans, sans-serif;letter-spacing: 0.0rem !important;' : 'color:#13293d;font-weight: bold;font-family: Work Sans, sans-serif;letter-spacing: 0.0rem !important;'"
            >
              {{model.title}}
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </section>
</template>

<script>
  export default {
    name: 'SectionSlideFirst',

    data: () => ({
      models: [
        {
          title: '6-axis robot arms in action for testing and demonstration purposes.',
          src: require('@/assets/facility_1.jpg'),
        },
        {
          title: 'Hands-on technical training on 6-axis robot arm software and hardware handling and trouble-shooting.',
          src: require('@/assets/facility_2.jpg'),
        },
        {
          title: 'Multi-purpose AR Training in training facility.',
          src: require('@/assets/facility_8.jpg'),
        },
        {
          title: 'Classroom training conducted in training facility.',
          src: require('@/assets/facility_3.jpg'),
        },
        {
          title: 'AGV as part of Smart Factory Solution Module in Demo/Training Facility.',
          src: require('@/assets/facility_4.jpg'),
        },
        {
          title: 'Calibration & Testing of Smart Warehouse and Retrieval Systems Solutions',
          src: require('@/assets/facility_5.jpg'),
        },
        {
          title: 'Integration of Robotic Tools for Project Design and Management',
          src: require('@/assets/facility_6.jpg'),
        },
        {
          title: 'Software simulation and Project Design Management Modules',
          src: require('@/assets/facility_7.jpg'),
        },
      ],
    }),
  }
</script>
